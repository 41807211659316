import style from "./header.scss";
import SecondaryButton from "../secondary-button/secondary-button";
import {useState} from "preact-compat";
import isMobile from "../../utils/device-detector";

const Header = ({active}) => {
    const mobile = isMobile();
    const [isOpen, setIsOpen] = useState(false);
    const [isUseCasesOpen, setIsUseCasesOpen] = useState(false);
    return (<>
        <div className={style.header}>
            <ul className={`${style.menu} ${isOpen && style.open}`}>
                <li className={`${style.closeIcon}`} onClick={() => {
                    setIsOpen(false);
                    setIsUseCasesOpen(false);
                }}>
                    <img src='/assets/icon/close.svg' alt={'close-icon'}/>
                </li>
                <a href={'/'}>
                    <li className={`${style.menuItem} ${active === 'Home' && style.active}`}>
                        Home
                    </li>
                </a>
                <li className={`${style.menuItem} ${isUseCasesOpen && style.selected}`} onClick={() => {
                    setIsUseCasesOpen(!isUseCasesOpen);
                }}>
                    Use Cases
                </li>
                <a href={'/pricing'}>
                    <li className={`${style.menuItem} ${active === 'Pricing' && style.active}`}>
                        Pricing
                    </li>
                </a>
                <li>
                    <a href={'https://calendly.com/power-up-work/demo'} target={'_blank'}>
                        <SecondaryButton haveArrow={true}>Contact Us</SecondaryButton>
                    </a>
                </li>
            </ul>
            {isUseCasesOpen && <div className={`${style.useCaseContainer}`}>
                <div className={style.discoverContentContainer}>
                    <div className={`${style.task} ${style.bg1}`}>
                        <img src={'/assets/icon/3d/sales.svg'} className={style.image}/>
                        <div className={style.title}>Sales & Prospecting</div>
                    </div>
                    <div className={`${style.task} ${style.bg2}`}>
                        <img src={'/assets/icon/3d/recruitment.png'} className={style.image}/>
                        <div className={style.title}>Recruiting</div>
                    </div>
                    <div className={`${style.task} ${style.bg3}`}>
                        <img src={'/assets/icon/3d/marketing.svg'} className={style.image}/>
                        <div className={style.title}>Marketing</div>
                    </div>
                    <div className={`${style.task} ${style.bg4}`}>
                        <img src={'/assets/icon/3d/tools.svg'} className={style.image}/>
                        <div className={style.title}>Build your own</div>
                    </div>
                </div>
            </div>}
            {isUseCasesOpen && <div className={style.backdrop} onClick={() => setIsUseCasesOpen(false)}></div>}
            <div className={style.container}>
                <div className={style.logoContainer}>
                    <a href={'/'}>
                        <img className={style.logo} src={'/assets/img/logo.png'}/>
                    </a>
                </div>
                {!mobile && <div className={style.menuContainer}>
                    <a href={'/'}>
                        <div className={`${style.menuItem} ${active === 'Home' && style.active}`}>
                            Home
                        </div>
                    </a>
                    <div className={`${style.menuItem} ${isUseCasesOpen && style.selected}`}
                         onClick={() => {
                             setIsUseCasesOpen(!isUseCasesOpen);
                         }}>
                        Use Cases
                    </div>
                    <a href={'/pricing'}>
                        <div className={`${style.menuItem} ${active === 'Pricing' && style.active}`}>
                            Pricing
                        </div>
                    </a>
                </div>}
                {!mobile && <div className={style.actionContainer}>
                    <a href={'https://calendly.com/power-up-work/demo'} target={'_blank'}>
                        <SecondaryButton haveArrow={true}>Contact Us</SecondaryButton>
                    </a>
                </div>}
                {mobile && <div className={style.menuIconContainer} onClick={() => setIsOpen(true)}>
                    <img src={'/assets/icon/menu.svg'}/>
                </div>}
            </div>
        </div>
    </>);
}

export default Header;
