import {useEffect, useState} from "preact-compat";

const isMobile = () => {
    const [mobile, setMobile] = useState(false);
    const checkMobile = () => {
        const userAgent = window.navigator.userAgent;
        setMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent));
    }
    useEffect(() => {
        if (typeof window !== 'undefined') {
            checkMobile();
        } else {
            setTimeout(() => {
                if (typeof window !== 'undefined') {
                    checkMobile();
                }
            }, 50);
        }
    }, []);

    return mobile;
}

export default isMobile;
