import style from "./price.scss";
import Text from "../../text/text";
import textStyle from "../../text/text.scss";
import PrimaryButton from "../../primary-button/primary-button";
import {useEffect, useState} from "preact-compat";

const Price = () => {
    const [billed, setBilled] = useState('annually');
    const [credits, setCredits] = useState(200);

    return (<>
        <div className={style.priceContainer}>
            <div>
                <Text type={'h1'} isWhite={false} isPurple={true}>Plans & Pricing</Text>
            </div>
            <div>
                <Text type={'sub-title'} isWhite={false}>Get Access to 10+ powerful AI models</Text>
            </div>
            <div className={style.radioContainer}>
                <div className={`${style.radioChild} ${billed === 'annually' && style.active}`}
                     onClick={() => {
                         setBilled('annually')
                     }}>
                    <Text type={'text'} isWhite={billed === 'annually'}>Annual (-20%)</Text>
                </div>
                <div className={`${style.radioChild} ${billed === 'monthly' && style.active}`}
                     onClick={() => {
                         setBilled('monthly')
                     }}>
                    <Text type={'text'} isWhite={billed === 'monthly'}>Monthly</Text>
                </div>
            </div>
            <div className={style.pricesContainer}>
                <div className={style.priceCard}>
                    <div className={style.priceCardHeader}>
                        <div>
                            <img className={style.icon} src={'/assets/icon/one-star.svg'}/>
                        </div>
                        <div>
                            <Text type={'h2'} isWhite={false}>Trial</Text>
                        </div>
                    </div>
                    <div>
                        <Text type={'text'} isWhite={false}>For individuals and small teams just getting started.</Text>
                    </div>
                    <div className={style.priceContent}>
                        <Text type={'h2'} isWhite={false} isPurple={true}>Free</Text>
                        <div className={style.features}>
                            <div>
                                <Text type={'text'} isBold={true} isWhite={false}>Complimentary Basic Access</Text>
                            </div>
                            <div>
                                <Text type={'text'} isBold={true} isWhite={false}>No Credit Card Needed</Text>
                            </div>
                        </div>
                    </div>
                    <div className={style.buttonContainer}>
                        <PrimaryButton isPurple={true}>
                            Get Started
                        </PrimaryButton>
                    </div>
                    <div className={style.features}>
                        <div>
                            <Text type={'text'} isBold={true} isWhite={false}>Includes:</Text>
                        </div>
                        <div>
                            <Text type={'text'} isWhite={false}>7 days trial</Text>
                        </div>
                        <div>
                            <Text type={'text'} isWhite={false}>Essential AI Toolkit Access</Text>
                        </div>
                    </div>
                </div>
                <div className={style.priceCard}>
                    <div className={style.priceCardHeader}>
                        <div>
                            <img className={style.icon} src={'/assets/icon/three-stars.svg'}/>
                        </div>
                        <div>
                            <Text type={'h2'} isWhite={false}>Pro</Text>
                        </div>
                    </div>
                    <div>
                        <Text type={'text'} isWhite={false}>For power users seeking advanced automation usage and
                            functionality.</Text>
                    </div>
                    <div className={style.priceContent}>
                        <div>
                            <Text type={'h2'} isWhite={false}
                                  isPurple={true}>${(credits / 100) * (billed === 'annually' ? 5 : 6)}/<span
                                className={style.priceTag}>month</span></Text>
                        </div>
                        <div className={style.priceDetails}>
                            <div>
                                <Text type={'text'} isBold={true} isWhite={false}>Customize</Text>
                            </div>
                            <div>
                                <Text type={'text'} isBold={true} isWhite={false}>{credits} credits/month</Text>
                            </div>
                        </div>
                        <div className={style.range}>
                            <input type="range" value={credits} min={200} max={2000} step={100}
                                   onChange={(e) => {
                                       setCredits(e.target.value)
                                   }}
                            />
                        </div>
                    </div>
                    <div className={style.buttonContainer}>
                        <PrimaryButton isPurple={true}>
                            Get Started
                        </PrimaryButton>
                    </div>
                    <div className={style.features}>
                        <div>
                            <Text type={'text'} isBold={true} isWhite={false}>Enables features:</Text>
                        </div>
                        <div>
                            <Text type={'text'} isWhite={false}>Unlimited AI Toolset access</Text>
                        </div>
                        <div>
                            <Text type={'text'} isWhite={false}>24/7 Expert Support</Text>
                        </div>
                    </div>
                </div>
                <div className={style.priceCard}>
                    <div className={style.priceCardHeader}>
                        <div>
                            <img className={style.icon} src={'/assets/icon/five-stars.svg'}/>
                        </div>
                        <div>
                            <Text type={'h2'} isWhite={false}>Star</Text>
                        </div>
                    </div>
                    <div>
                        <Text type={'text'} isWhite={false}>For larger teams who need more security and control.</Text>
                    </div>
                    <div className={style.priceContent}>
                        <Text type={'h2'} isWhite={false} isPurple={true}>Custom</Text>
                        <div className={style.features}>
                            <div>
                                <Text type={'text'} isBold={true} isWhite={false}>Customised AI from Your Data</Text>
                            </div>
                            <div>
                                <Text type={'text'} isBold={true} isWhite={false}>Custom AI Tailored to Your Business
                                    Needs</Text>
                            </div>
                        </div>
                    </div>
                    <div className={style.buttonContainer}>
                        <PrimaryButton isPurple={true}>
                            Get Started
                        </PrimaryButton>
                    </div>
                    <div className={style.features}>
                        <div>
                            <Text type={'text'} isBold={true} isWhite={false}>Enables features:</Text>
                        </div>
                        <div>
                            <Text type={'text'} isWhite={false}>Tailored AI Model</Text>
                        </div>
                        <div>
                            <Text type={'text'} isWhite={false}>Advanced Data Insights</Text>
                        </div>
                        <div>
                            <Text type={'text'} isWhite={false}>Exclusive Onboarding & Training</Text>
                        </div>
                        <div>
                            <Text type={'text'} isWhite={false}>Front-Line Customer Support</Text>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
};

export default Price;
