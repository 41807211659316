import {Provider} from '@preact/prerender-data-provider';
import {Router} from "preact-router";
import './styles/main.scss';
import Privacy from "./routes/privacy/privacy";
import Landing from "./routes/landing/landing";
import Pricing from "./routes/pricing/pricing";

if (process.env.NODE_ENV === 'development') {
    require("preact/debug");
}

const App = (props) => {


    return (
        <Provider value={props}>
            <Router>
                <Landing path="/"/>
                <Pricing path="/pricing"/>
                <Privacy path="/privacy-policy"/>
            </Router>
        </Provider>
    )
}

export default App;
