import style from "./secondary-button.scss";
import Ripple from "../ripple/ripple";

const SecondaryButton = ({children, onClick, isDisabled, haveArrow = false}) => (
    <Ripple onClick={onClick} className={`${style.secondaryButton}`}>
        <button className={`${style.button} ${isDisabled && style.disabled}`}>
            {children}
            {haveArrow && <div className={style.arrow}>
                <img src={'/assets/icon/next.svg'}/>
            </div>}
        </button>
    </Ripple>
);

export default SecondaryButton;
