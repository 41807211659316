import Hero from "../../components/route-sections/hero/hero";
import Header from "../../components/header/header";
import Discover from "../../components/route-sections/discover/discover";
import Integrations from "../../components/route-sections/integrations/integrations";
import Footer from "../../components/footer/footer";
import {useEffect} from "preact-compat";

const Landing = () => {

    useEffect(() => {
        setTimeout(() => {
            if (typeof window !== 'undefined') {
                window.scrollTo(0, 0);
            }
        }, 10);
    }, []);

    return (<>
        <Header active={'Home'}/>
        <Hero/>
        <Discover/>
        <Integrations/>
        <Footer/>
    </>)
};

export default Landing;
