import style from "./text.scss";

const Text = ({
                  type,
                  children,
                  isWhite = true,
                  isPrimary = false,
                  isPurple = false,
                  isBold = false,
                  link = null,
                  fontSize = null,
                  onClick = null
              }) => {
    return (<>
            {type === 'h1' && (
                <h1 className={`${style.h1} ${isWhite && style.cWhite} ${isPrimary && style.cPrimary} ${isPurple && style.cPurple}`}>{children}</h1>)}
            {type === 'h2' && (
                <h2 className={`${style.h2} ${isWhite && style.cWhite} ${isPrimary && style.cPrimary} ${isPurple && style.cPurple}`}>{children}</h2>)}
            {type === 'h3' && (
                <h3 className={`${style.h3} ${isWhite && style.cWhite} ${isPrimary && style.cPrimary}`}>{children}</h3>)}
            {type === 'sub-title' && (
                <p className={`${style.subTitle} ${isWhite && style.cWhite} ${isPrimary && style.cPrimary} ${isBold && style.bold}`}>{children}</p>)}
            {type === 'text' && (
                <p className={`${style.text} ${isWhite && style.cWhite} ${isPrimary && style.cPrimary} ${isBold && style.bold}`}>{children}</p>)}
            {type === 'link' && (
                <a href={link} onClick={onClick}
                   className={`${style.aLink} ${isWhite && style.cWhite} ${isPrimary && style.cPrimary}`}>{children}</a>)}
        </>
    );
}

export default Text;
