import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Text from "../../components/text/text";
import style from "./privacy.scss";

const Privacy = () => {
    return (<>
        <Header/>
        <div className={style.privacyContainer}>
            <Text type={'h1'} isPrimary={true}>Privacy Policy</Text>
            <div>
                <Text type={'text'}>
                    This is the Privacy Policy of Stiletto website: https://www.stiletto.com(the “Site”).
                    It describes the information that we collect from you as part of the normal operation of our Site,
                    and how we disclose and secure this information. It also describes your choices regarding use,
                    access and correction of your personal information.
                </Text>
            </div>
            <div>
                <Text type={'text'}>
                    Our primary purpose in collecting personal information from you is to provide you with a safe,
                    smooth, efficient, and customized experience. This allows us to provide services and features that
                    most likely meet your needs, and to customize our service to make your experience safer and easier.
                    We only collect personal information about you that we consider necessary for achieving this
                    purpose.
                </Text>
            </div>
            <div>
                <Text type={'text'}>
                    In general, you can browse the Site without telling us who you are or revealing personal information
                    about yourself.
                </Text>
            </div>
            <div>
                <Text type={'text'} isPrimary={true} isBold={true}>
                    1. Collection of Personal Information
                </Text>
                <Text type={'text'}>
                    We may collect personal information from you when you sign up for our service or provide information
                    through our website. This information may include your name, email address, phone number, and other
                    information relevant to our services.
                </Text>
                <Text type={'text'}>
                    You always have the option to not provide information by choosing not to become a User or by not
                    using the particular feature of the Site for which the information is being collected.
                </Text>
            </div>
            <div>
                <Text type={'text'} isPrimary={true} isBold={true}>
                    2. Protection of Personal Information
                </Text>
                <Text type={'text'}>
                    We take reasonable measures to protect your personal information from unauthorized access or
                    disclosure. We maintain physical, electronic, and procedural safeguards to protect your personal
                    information. We limit access to your personal information to those who have a need to know it to
                    provide services to you.
                </Text>
            </div>
            <div>
                <Text type={'text'} isPrimary={true} isBold={true}>
                    3. Sharing of Personal Information
                </Text>
                <Text type={'text'}>
                    We do not share your personal information with third parties, except as necessary to provide
                    services to you or as required by law.
                </Text>
            </div>
            <div>
                <Text type={'text'} isPrimary={true} isBold={true}>
                    4. Contacting Us
                </Text>
                <Text type={'text'}>
                    If you have any questions or concerns about our privacy practices, please contact us at [insert
                    contact information here]. We will respond to your inquiry promptly.
                </Text>
            </div>
            <div>
                <Text type={'text'} isPrimary={true} isBold={true}>
                    5. Changes to the Privacy Policy
                </Text>
                <Text type={'text'}>
                    We reserve the right to update or modify this privacy policy at any time. If we make material
                    changes to this policy, we will provide notice through our website or by other means.
                </Text>
            </div>
            <div>
                <Text type={'text'} isPrimary={true} isBold={true}>
                    6. Applicable Law
                </Text>
                <Text type={'text'}>This privacy policy is governed by and construed in accordance with the laws of
                    Dubai.
                </Text>
            </div>

        </div>
        <Footer/>
    </>)
};

export default Privacy;
