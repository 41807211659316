import style from "./primary-button.scss";
import Ripple from "../ripple/ripple";

const PrimaryButton = ({children, onClick, isDisabled, isPurple = false}) => (
    <Ripple onClick={onClick} className={`${style.primaryButton} ${isPurple && style.bgPurple}`}>
        <button className={`${style.button} ${isDisabled && style.disabled}`}>
            {children}
        </button>
    </Ripple>
);

export default PrimaryButton;
