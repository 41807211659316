import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Price from "../../components/route-sections/price/price";
import {useEffect} from "preact-compat";

const Pricing = () => {

    useEffect(() => {
        setTimeout(() => {
            if (typeof window !== 'undefined') {
                window.scrollTo(0, 0);
            }
        }, 10);
    }, []);
    return (<>
        <Header active={'Pricing'}/>
        <Price/>
        <Footer/>
    </>)
};

export default Pricing;
