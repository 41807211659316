import style from "./footer.scss";
import Text from "../text/text";

const Footer = ({links = []}) => {

    return (<>
        <div className={style.footerContainer}>
            <div>
                <Text type={'text'}>©2023 POWERUP</Text>
            </div>
        </div>
    </>)
};

export default Footer;
