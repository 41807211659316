import style from "./integration.scss";
import Text from "../../text/text";
import textStyle from "../../text/text.scss";
import PrimaryButton from "../../primary-button/primary-button";

const Integrations = () => {
    const tools = [
        'affinity',
        'airtable',
        'asana',
        'clearbit',
        'clickup',
        'dropbox',
        'github',
        'google-calendar',
        'google-docs',
        'google-drive',
        'google-mail',
        'google-meet',
        'google-sheets',
        'hubspot',
        'jira',
        'linkedin',
        'notion',
        'open-ai',
        'salesforce',
        'slack',
        'trello',
        'twitter',
        'whatsapp',
        'youtube'
    ];
    return (<>
        <div className={style.integrationContainer}>
            <Text type={'h2'} isWhite={false} isPurple={true}>Get more out of your<br/>favorite apps</Text>
            <div className={style.integrationContentContainer}>
                {tools.map((tool, i) => {
                    return (<div key={i} className={style.tool}>
                        <img className={style.image} src={`/assets/img/tools/${tool}.svg`}/>
                    </div>)
                })}
            </div>
            <div className={style.boostContainer}>
                <div>
                    <img src={'/assets/icon/rocket.svg'}/>
                </div>
                <div>
                    <Text type={'h2'}>Increase your performance <span
                        className={textStyle.cPrimary}>Today</span></Text>
                </div>
                <div className={style.buttonSection}>
                    <a href={'https://calendly.com/power-up-work/demo'} target={'_blank'}>
                        <PrimaryButton>Try it Now</PrimaryButton>
                    </a>
                </div>
            </div>
        </div>
    </>)
};

export default Integrations;
