import style from "./hero-animation.scss";
import SecondaryButton from "../secondary-button/secondary-button";
import textStyle from "../text/text.scss";
import {useState} from "preact-compat";
import Text from "../text/text";
import AiAnimatedMessage from "../ai-animated-message/AiAnimatedMessage";

const HeroAnimation = () => {

    const [isButtonVisible, setIsButtonVisible] = useState(false);
    const [step, setStep] = useState(1);
    const doneWriting = () => {
        setIsButtonVisible(true);
        setTimeout(() => {
            setStep(2);
        }, 2000);
    }

    return (<>
        <div className={style.animationContainer}>
            {step === 1 && <div className={style.step1}>
                <div className={style.goal}>
                    <AiAnimatedMessage
                        content={'Generate a tiktok viral-video script.'}
                        doneWriting={doneWriting}/>
                </div>
                <div style={{visibility: isButtonVisible ? 'visible' : 'hidden'}}
                     className={`${isButtonVisible && style.showFade}`}>
                    <SecondaryButton>Let’s do it for you</SecondaryButton>
                </div>
                {isButtonVisible && <img src={'/assets/icon/cursor.svg'} className={style.cursor}/>}
                {isButtonVisible && <div className={style.showClick}></div>}
            </div>}
            {step === 2 && <div className={style.step2}>
                <img src={'/assets/icon/magic.svg'} className={style.showUp}/>
                <div className={style.goal2}>
                    <div className={`${style.miniStep1} ${style.miniStep}`}>
                        <div className={`${style.miniStepContainer} ${style.text1}`}>
                            <div className={`${style.stepContainer}`}>
                                <img src={'/assets/icon/download.svg'}/>
                                <div className={style.masked} style={{width: '160px'}}></div>
                            </div>
                            <div>
                                <Text type={'text'}>
                                    <span className={textStyle.bold}>Fetch & Learn</span> latest viral videos
                                </Text>
                            </div>
                        </div>
                    </div>
                    <div className={`${style.miniStep3} ${style.miniStep}`}>
                        <div className={`${style.miniStepContainer} ${style.text3}`}>
                            <div className={`${style.stepContainer}`}>
                                <img src={'/assets/icon/ai.svg'}/>
                                <div className={style.masked} style={{width: '140px'}}></div>
                            </div>
                            <div>
                                <Text type={'text'}>
                                    <span className={textStyle.bold}>Generate</span> a tailored video script.
                                </Text>
                            </div>
                        </div>
                    </div>
                    <div className={`${style.miniStep4} ${style.miniStep}`}>
                        <div className={`${style.miniStepContainer} ${style.text4}`}>
                            <div className={`${style.stepContainer}`}>
                                <img src={'/assets/icon/email-white.svg'}/>
                                <div className={style.masked} style={{width: '110px'}}></div>
                            </div>
                            <div>
                                <Text type={'text'}>
                                    <span className={textStyle.bold}>Email</span> the script to Ines.
                                </Text>
                            </div>
                        </div>
                    </div>
                    <div className={style.userReviewContainer}>
                        <img className={style.userImage} src={'/assets/img/user-ines.png'}/>
                        <div className={style.userReview}>
                            <Text type={'text'}>Ines<span className={textStyle.bold}> saves 26h / month.</span> by using
                                TikTok Ghost.</Text>
                        </div>
                    </div>
                </div>
                <div className={`${style.task}`}>
                    <div className={style.icons}>
                        <img src={'/assets/icon/tiktok.svg'}/>
                        <img src={'/assets/icon/video.svg'}/>
                        <img src={'/assets/icon/gmail.svg'}/>
                    </div>
                    <div className={style.title}>Tiktok Ghost</div>
                    <div className={style.description}>Analyze a top-performing video from
                        a selected TikTok influencer, then
                        craft a tailored video script based
                        on your unique idea.
                    </div>
                    <div className={style.saveup}>Saves 2 hours</div>
                </div>
            </div>}
        </div>
    </>);
}

export default HeroAnimation;
