import style from "./hero.scss";
import Text from "../../text/text";
import PrimaryButton from "../../primary-button/primary-button";

import textStyle from "../../text/text.scss";
import HeroAnimation from "../../hero-animation/hero-animation";

import HeroAnimationMobile from "../../hero-animation-mobile/hero-animation-mobile";
import isMobile from "../../../utils/device-detector";

const Hero = () => {
    const mobile = isMobile();
    return (<>
        <div className={style.heroContainer}>
            <div className={style.contentHeaderContainer}>
                <div className={style.headerContainer}>
                    <div>
                        <Text type={'h1'}>Increase<br/>your<br/>performance<br/><span
                            className={textStyle.cPrimary}>up to 40%</span></Text>
                    </div>
                    <div>
                        <Text type={'sub-title'}>Tap into the AI solutions to automate your everyday tasks.</Text>
                    </div>
                    <div className={`${style.buttonsContainer}`}>
                        <a href={'https://calendly.com/power-up-work/demo'} target={'_blank'}>
                            <PrimaryButton>Try it Now</PrimaryButton>
                        </a>
                    </div>
                </div>
                <div>
                    {!mobile && <HeroAnimation></HeroAnimation>}
                    {mobile && <HeroAnimationMobile></HeroAnimationMobile>}
                </div>
            </div>
            <div className={style.hintContainer}>
                <div className={style.hintHeader}>
                    <div>
                        <img src={'/assets/icon/hint.svg'}/>
                    </div>
                    <div>
                        <Text type={'h2'} isWhite={false}>Boost Your Performance with AI</Text>
                    </div>
                </div>
                <div>
                    <Text type={'text'} isWhite={false}>Utilize AI-trained tools for specialized tasks and save up to
                        40% of your time.
                        For instance,
                        employing ChatGPT can reduce task time by 10%, freeing you for strategic and
                        creative endeavors.</Text>
                </div>
            </div>
        </div>
    </>)
};

export default Hero;
