import style from "./discover.scss";
import Text from "../../text/text";

const Discover = () => {

    return (<>
        <div className={style.discoverContainer}>
            <Text type={'h2'} isWhite={false} isPurple={true}>Discover What You <br/>Can Streamline with AI</Text>
            <div className={style.discoverContentContainer}>
                <div className={`${style.task} ${style.bg1}`}>
                    <img src={'/assets/icon/3d/sales.svg'} className={style.image}/>
                    <div className={style.title}>Sales & Prospecting</div>
                </div>
                <div className={`${style.task} ${style.bg2}`}>
                    <img src={'/assets/icon/3d/recruitment.png'} className={style.image}/>
                    <div className={style.title}>Recruiting</div>
                </div>
                <div className={`${style.task} ${style.bg3}`}>
                    <img src={'/assets/icon/3d/marketing.svg'} className={style.image}/>
                    <div className={style.title}>Marketing</div>
                </div>
                <div className={`${style.task} ${style.bg4}`}>
                    <img src={'/assets/icon/3d/tools.svg'} className={style.image}/>
                    <div className={style.title}>Build your own</div>
                </div>
            </div>
        </div>
    </>)
};

export default Discover;
