import React from 'react';
import {useEffect} from "preact-compat";
import style from "./AiAnimatedMessage.scss";


const AiAnimatedMessage = ({content, doneWriting}) => {

    const [displayResponse, setDisplayResponse] = React.useState('');
    const [completedTyping, setCompletedTyping] = React.useState(false);
    const [intervalId, setIntervalId] = React.useState(null);

    const write = () => {
        setCompletedTyping(false);

        let i = 0;

        const interval = setInterval(() => {
            setDisplayResponse(content.slice(0, i));

            i++;

            if (i > content.length) {
                clearInterval(interval);
                setCompletedTyping(true);
                if (doneWriting) {
                    doneWriting();
                }
            }
        }, 20);
        setIntervalId(interval);
    }

    useEffect(() => {
        if (!content?.length) {
            return;
        }

        write();

        return () => clearInterval(intervalId);
    }, []);

    return (<div>
        <span style={{color: '#ffffff'}} dangerouslySetInnerHTML={{__html: displayResponse}}></span>
        {!completedTyping && <svg
            viewBox="8 4 8 16"
            xmlns="http://www.w3.org/2000/svg"
            className={style.cursor}>
            <rect x="10" y="6" width="4" height="12" fill={'#ffffff'}/>
        </svg>}
    </div>);
};

export default AiAnimatedMessage;
